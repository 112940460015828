import Phaser from "phaser";
import GameConstants from "@/game/pacman/utils/GameConstants";
import HUDBox from "@/game/shared/components/HUDBox";
import {parseHexColor} from "@/game/shared/utils/Helpers";
import Setup from "@/helpers/Setup";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import {ScoreDataDefault} from "@/interfaces/ISessionFile";

export default class HUD extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);
        this.scene.add.existing(this);

        //Get colors
        const primaryTextColor = parseHexColor(Setup.getValue("settings.game.colors.primarytextcolor"));
        const primaryColor = parseHexColor(Setup.getValue("settings.game.colors.primarycolor"));
        const secondaryColor = parseHexColor(Setup.getValue("settings.game.colors.secondarycolor"));

        //Create coin box
        const scoreBox = new HUDBox(this.scene, GameConstants.UI_DEFAULT_MARGIN, GameConstants.UI_DEFAULT_MARGIN, {
            iconTexture: "ui-coins",
            value: "0",
            minWidth: 50,
            color: {
                iconBox: primaryColor.color,
                textBox: secondaryColor.color,
                text: primaryTextColor.colorString
            }
        });
        scoreBox.updateText(this.scene.registry.get("score"));

        //Create health box
        const livesBox = new HUDBox(this.scene, this.scene.cameras.main.width - GameConstants.UI_DEFAULT_MARGIN, GameConstants.UI_DEFAULT_MARGIN, {
            iconTexture: "ui-lives",
            value: "",
            minWidth: 50,
            origin: new Phaser.Math.Vector2(1, 0.5),
            color: {
                iconBox: primaryColor.color,
                textBox: secondaryColor.color,
                text: primaryTextColor.colorString
            }
        });
        livesBox.updateText(this.scene.registry.get("lives")); //Set text like this, as this triggers resize event

        //Add views
        this.add([scoreBox, livesBox]);

        //Set registry listener
        this.scene.registry.events.on("changedata", (parent: any, key: string, data: any) => {
            switch(key) {
                case "score":
                    scoreBox.updateText(data);
                    sessionSaveFile.updateValue(ScoreDataDefault.TOTALSCORE, parseInt(data));
                    break;
                case "lives":
                    livesBox.updateText(data);
                    break;
            }
        });
    }

    destroy(fromScene?: boolean) {
        this.scene.registry.events.off("changedata");

        //Run actual destroy
        super.destroy(fromScene);
    }
}