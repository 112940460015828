import BaseEnemyAI from "@/game/pacman/components/ai/BaseEnemyAI";
import {IEnemyAI} from "@/game/pacman/interfaces/IEnemyAI";
import Phaser from "phaser";

export default class InterceptAI extends BaseEnemyAI implements IEnemyAI {

    public getTargetPosition(x: number, y: number): Phaser.Math.Vector2 {
        const nextPlayerPoint = this.enemyInstance.playerRef.getNextPointInDirection();
        return new Phaser.Math.Vector2(nextPlayerPoint.x, nextPlayerPoint.y);
    }
}