import AnimatedTile from "../components/AnimatedTile";
import Player from "../components/entities/Player";
import GameConstants, {EnemyType, PickupType} from "../utils/GameConstants";
import Phaser from "phaser";
import HUD from "../components/hud/HUD";
import Enemy from "../components/entities/Enemy";
import PopupDialog from "../components/dialogs/PopupDialog";
import {getGameSpeed} from "../utils/Helpers";
import ScoreNotifier from "../components/dialogs/ScoreNotifier";
import ITransitionConfig from "../interfaces/ITransitionConfig";
import Setup from "@/helpers/Setup";
import {mapRange} from "@/game/shared/utils/Helpers";
import Scaling from "@/game/shared/utils/Scaling";
import Joystick, {JoystickMode} from "@/game/shared/components/Joystick";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import {ScoreDataDefault, ScoreGameTypes} from "@/interfaces/ISessionFile";
import scoreController from "@/utils/game/ScoreController";

export default class Main extends Phaser.Scene {

    //Levels
    private readonly levelList: string[] = ["level1", "level2", "level3", "level4", "level5"];
    private readonly musicList: string[] = ["music-loop1", "music-loop2", "music-loop3", "music-loop4"];

    //State
    private level?: string;
    private score = 0;
    private round = 0;
    private coins = 0;
    private coinCount = 0;
    private hitMultiplier = 0;
    private firstRun = true
    private resetState = true;
    private running = false;
    private isGameOver = false;

    //Game
    private baseLayer?: Phaser.Tilemaps.TilemapLayer;
    private pickupLayer?: Phaser.Tilemaps.TilemapLayer;
    private player?: Player;
    private hud?: HUD;
    private enemies: Enemy[] = [];
    private baseX = 0;
    private baseY = 0;

    //Misc
    private animatedTiles: AnimatedTile[] = [];
    private powerUpTimer?: Phaser.Time.TimerEvent;

    private joystick!: Joystick;
    private keyboard!: Phaser.Types.Input.Keyboard.CursorKeys;
    private spawnPositionX!: number
    private spawnPositionY!: number

    constructor() {
        super({ key: "game" });
    }

    init(data: ITransitionConfig) {
        this.spawnPositionX = data.x;
        this.spawnPositionY = data.y;
    }

    create() {
        sessionSaveFile.create({
            type: ScoreGameTypes.INFINITE,
            stats: ["enemiesHit", "coins", "powerUps", "rounds"]
        });

        //Emit end event
        this.game.events.emit("start", this.round + 1);
        this.isGameOver = false;

        //Set initial game values, if we need to reset
        if (this.resetState) {
            this.registry.set("lives", GameConstants.GAME_LIVES_TOTAL);
            this.registry.set("score", 0);
            this.registry.set("round", 0);
            this.resetState = false;
        }

        //Get values
        this.level = this.registry.get("level");
        this.score = this.registry.get("score");
        this.round = this.registry.get("round");

        //Determine level & store in registry
        const levelList = this.levelList.length >= 2 ? this.levelList.filter(name => name !== this.level) : this.levelList;
        const level = Phaser.Math.RND.pick(levelList);
        this.registry.set("level", level);

        //Load map & tiles
        const baseScale = Scaling.DPR / 3; //Must be in thirds, to preserve round pixels
        const map = this.add.tilemap(`${level}-map`);
        const baseTiles = map.addTilesetImage("base-tilemap", "tilemap-base");
        const pickupTiles = map.addTilesetImage("pickup-tilemap", "tilemap-pickup");

        //Setup base
        this.baseLayer = map.createLayer("base", baseTiles, 0, 0);
        this.baseLayer.setScale(baseScale);
        this.baseLayer.setCollisionByProperty({ collides: true });

        //Position base
        this.baseX = (this.cameras.main.width - this.baseLayer.displayWidth) / 2;
        this.baseY = (this.cameras.main.height - this.baseLayer.displayHeight) / 2;
        this.baseLayer.setPosition(this.baseX, this.baseY);

        //Create coin & power-up layer
        this.pickupLayer = map.createLayer("pickups", pickupTiles, this.baseX, this.baseY);
        this.pickupLayer.setScale(baseScale);
        this.coinCount = this.pickupLayer.filterTiles((object: any) => object["properties"] && object["properties"]["type"] === "coin").length;

        //Spawn player
        const playerObject = map.findObject("player", object => object);
        const playerX = (playerObject.x || 0) * baseScale;
        const playerY = (playerObject.y || 0) * baseScale;
        this.player = new Player(this, playerX + this.baseX, playerY + this.baseY, this.baseLayer, this.round);

        //Spawn enemies
        const enemyObjects = map.filterObjects("enemies", object => object);
        for (let i = 0; i < enemyObjects.length; i++) {
            if (i > this.round) {
                continue;
            }

            //Calculate positions
            const enemyX = (enemyObjects[i].x || 0) * baseScale;
            const enemyY = (enemyObjects[i].y || 0) * baseScale;

            //Spawn enemies
            const enemy = new Enemy(this, enemyX + this.baseX, enemyY + this.baseY, enemyObjects[i].name, this.baseLayer, this.player, this.round);
            this.enemies.push(enemy);
        }

        // set up joystick
        this.joystick = new Joystick(this, this.cameras.main.centerX, 0, {
            isFixed: true,
            mode: JoystickMode.FOUR
        })
        this.joystick.setY(this.cameras.main.displayHeight - this.joystick.getBounds().height / 2 - Scaling.getPixelbyDPR(32));
        this.keyboard = this.input.keyboard.createCursorKeys();

        //Add HUD
        this.hud = new HUD(this, 0, Scaling.getPixelbyDPR(40));

        //Set overlaps
        this.physics.add.overlap(this.player, this.pickupLayer, this.onPickupOverlap.bind(this));
        this.physics.add.overlap(this.player, this.enemies, this.onEnemyOverlap.bind(this));

        //Set colliders
        this.physics.add.collider(this.player, this.baseLayer);
        this.physics.add.collider(this.enemies, this.baseLayer);

        //Set events
        this.events.on("precleanup", this.preCleanUp.bind(this));
        this.events.on("gameover", this.gameOver.bind(this));

        //Add animations
        this.animatedTiles = AnimatedTile.getAnimationTiles(map, pickupTiles)

        //Create transition dialog
        const dialog = new PopupDialog(this, 0, 0, `ROUND ${this.round + 1}`, true);

        // start animation
        const tweenDuration = 2000

        if (this.firstRun) {
            this.player?.setActive(false).setVisible(false)
            const animationSprite = this.add.sprite(this.spawnPositionX, this.spawnPositionY, 'player')
            this.anims.play("animate-player", animationSprite);

            this.tweens.add({
                targets: [this.baseLayer, this.pickupLayer],
                alpha: { from: 0, to: 1 },
                duration: tweenDuration
            })

            this.tweens.add({
                targets: animationSprite,
                x: { from: this.spawnPositionX, to: this.player.x },
                y: { from: this.spawnPositionY, to: this.player.y },
                duration: tweenDuration,
                ease: Phaser.Math.Easing.Cubic.InOut,
                onComplete: () => {
                    //Hide after small delay
                    this.tweens.add({
                        targets: [dialog],
                        duration: 400,
                        delay: 800,
                        alpha: 0,
                        onComplete: () => {
                            dialog.destroy(true);
                            animationSprite.setActive(false).setVisible(false)
                            this.player?.setActive(true).setVisible(true)
                            this.running = true;

                            //Play song
                            this.sound.play(Phaser.Math.RND.pick(this.musicList), { loop: true, volume: 0.6 });
                        }
                    });
                }
            })
        } else {
            this.tweens.add({
                targets: [dialog],
                duration: 400,
                delay: 1600,
                alpha: 0,
                onComplete: () => {
                    dialog.destroy(true);
                    this.running = true;

                    //Play song
                    this.sound.play(Phaser.Math.RND.pick(this.musicList), { loop: true, volume: 0.6 });
                }
            });
        }
    }

    onPickupOverlap(playerObj: unknown, tileObj: unknown) {
        const player = playerObj as Player;
        const tile = tileObj as Phaser.Tilemaps.Tile;

        //Skip if invalid tile
        if (tile.index === -1) {
            return;
        }

        //Calculate distance, only remove when close enough
        const distance = Phaser.Math.Distance.Between(player.x, player.y, tile.getCenterX(), tile.getCenterY());
        if (distance <= GameConstants.MAP_HALF_TILE_SIZE) {
            this.pickupLayer?.removeTileAt(tile.x, tile.y);

            //Handle based on type
            const tileType = tile.properties["type"] as PickupType;
            switch (tileType) {
                case PickupType.COIN:
                    const gameSpeed = getGameSpeed(this.round);
                    const playRate = mapRange(gameSpeed, GameConstants.GAME_BASE_SPEED, GameConstants.GAME_SPEED_LIMIT, 1, 2);

                    //Play pickup sound
                    this.sound.stopByKey("collect-coin");
                    this.sound.play("collect-coin", { rate: playRate, volume: 0.75 });

                    //Increment counters
                    this.coins += 1;
                    this.score += 10 * (this.player?.isPoweredUp() ? 2 : 1);
                    this.registry.set("score", this.score);
                    sessionSaveFile.incrementValue("coins", 1);

                    //Completion check
                    if (this.coins === this.coinCount) {
                        this.nextLevel();
                    }
                    break;
                case PickupType.POWERUP_ONE:
                case PickupType.POWERUP_TWO:
                case PickupType.POWERUP_THREE:
                    sessionSaveFile.incrementValue("powerUps", 1);
                    this.enablePowerUp();
                    break;
            }
        }
    }

    onEnemyOverlap(playerObj: unknown, enemyObj: unknown) {
        const player = playerObj as Player;
        const enemy = enemyObj as Enemy;

        const distance = Phaser.Math.Distance.Between(player.x, player.y, enemy.x, enemy.y);
        if (distance <= GameConstants.MAP_HALF_TILE_SIZE) {
            if (player.isPoweredUp()) {
                const result = enemy.hit();

                //Increment score, if actually hit
                if (result) {
                    const points = 100 * ++this.hitMultiplier
                    this.score += points;
                    this.registry.set("score", this.score);
                    sessionSaveFile.incrementValue("enemiesHit", 1);

                    //Spawn score notifier
                    if (this.player) {
                        new ScoreNotifier(this, this.player.x, this.player.y - GameConstants.MAP_TILE_SIZE, points);
                    }
                }
            }
            else {
                player.hit();
            }
        }
    }

    enablePowerUp() {
        this.enemies.forEach(enemy => enemy.setScatterMode(true));
        this.player?.setPoweredUp(true);
        this.sound.play("power-up");

        //Remove existing reset timer
        if (this.powerUpTimer) {
            this.time.removeEvent(this.powerUpTimer);
            this.powerUpTimer = undefined;
        }

        //Set timer
        this.powerUpTimer = this.time.addEvent({
            delay: GameConstants.GAME_POWERUP_DURATION,
            callback: () => {
                if(!this.isGameOver){
                    this.enemies.forEach(enemy => enemy.setScatterMode(false));
                    this.player?.setPoweredUp(false);
                    this.sound.play("power-down");
                    this.hitMultiplier = 0;
                }
            }
        });
    }

    nextLevel() {
        this.preCleanUp();

        //Increment current round
        this.round += 1;
        this.registry.set("round", this.round);
        this.firstRun = false

        //Play victory sound
        this.sound.play("victory");

        //Show level complete popup
        const popupDialog = new PopupDialog(this, 0, 0, Setup.getCopy('general.completeLevel'), true, true);
        popupDialog.on("visible", () => {
            this.time.addEvent({
                delay: 2000,
                callback: () => {
                    popupDialog.destroy(true);

                    //Reload scene
                    this.cleanUp();
                    this.scene.restart()
                }
            })
        });
    }

    preCleanUp() {
        this.running = false;
        this.sound.stopAll();

        //Freeze movements
        this.player?.halt();
        this.enemies.forEach(enemy => enemy.destroy());
    }

    cleanUp() {
        this.animatedTiles = [];

        //Destroy stuff
        this.player?.destroy();
        this.hud?.destroy();

        //Remove items
        this.enemies = [];
        this.player = undefined;
        this.hud = undefined;

        //Reset vars
        this.coins = 0;
    }

    gameOver() {
        this.isGameOver = true;
        if (this.resetState) {
            return;
        }

        //Run cleanup
        this.cleanUp();

        //Update highscore
        this.round++;
        sessionSaveFile.updateValue(ScoreDataDefault.HIGHSCORE, this.score);
        sessionSaveFile.updateValue("rounds", this.round);

        //Emit end event
        const result = scoreController.getResult();
        this.game.events.emit("end", result);

        //Mark reset & stop
        this.resetState = true;
        this.scene.stop();
    }

    update(time: number, delta: number) {
        this.animatedTiles.forEach(animatedTile => animatedTile.update(delta));

        //Block updates until in running state
        if (!this.running) {
            return;
        }

        //Update joystick
        this.joystick.update();

        //Update player
        this.player?.update(time, delta, this.joystick.getDirection())

        //Update enemies
        this.enemies.forEach(enemy => {
            switch (enemy.type) {
                case EnemyType.FLANK:
                    const chaseEnemy = this.enemies.find(value => value.type === EnemyType.CHASE);
                    if (chaseEnemy) {
                        enemy.update(time, delta, chaseEnemy.x, chaseEnemy.y);
                    }
                    break;
                default:
                    enemy.update(time, delta);
                    break;
            }
        });
    }
}
