import Scaling from "@/game/shared/utils/Scaling";

export default class GameConstants {

    //Background
    static BACKGROUND_SPEED = 0.5;

    //Sizes
    static MAP_TILE_SIZE = 22;
    static MAP_HALF_TILE_SIZE = 0;
    static UI_DEFAULT_MARGIN = 20;
    static UI_DEFAULT_PADDING = 10;
    static UI_BOX_BASE_SIZE = 30;
    static UI_BOX_RADIUS = 5;
    static GAME_TURN_THRESHOLD = 5;
    static GAME_TURN_DELAY = 400;

    //Game
    static GAME_BASE_SPEED = 60;
    static GAME_SPEED_INCREMENT = 5;
    static GAME_SPEED_LIMIT = 120;
    static GAME_SPEED_MULTIPLIER_LIMIT = GameConstants.GAME_SPEED_LIMIT + GameConstants.GAME_BASE_SPEED;
    static GAME_LIVES_TOTAL = 3;
    static GAME_POWERUP_DURATION = 7000;

    static updateWithScaling() {
        this.MAP_TILE_SIZE *= Scaling.DPR;
        this.MAP_HALF_TILE_SIZE = this.MAP_TILE_SIZE / 2;
        this.UI_DEFAULT_MARGIN *= Scaling.DPR;
        this.UI_DEFAULT_PADDING *= Scaling.DPR;
        this.UI_BOX_BASE_SIZE *= Scaling.DPR;
        this.UI_BOX_RADIUS *= Scaling.DPR;
        this.GAME_TURN_THRESHOLD *= Scaling.DPR;
        this.GAME_BASE_SPEED *= Scaling.DPR;
        this.GAME_SPEED_INCREMENT *= Scaling.DPR;
        this.GAME_SPEED_LIMIT *= Scaling.DPR;
        this.GAME_SPEED_MULTIPLIER_LIMIT *= Scaling.DPR;
    }
}

export enum EnemyType {
    CHASE = "enemy-chase",
    FLANK = "enemy-flank",
    INTERCEPT = "enemy-intercept",
    PLAYFUL = "enemy-playful"
}

export enum PickupType {
    COIN = "coin",
    POWERUP_ONE = "powerup_one",
    POWERUP_TWO = "powerup_two",
    POWERUP_THREE = "powerup_three"
}
