import Phaser from "phaser";

export default class Background extends Phaser.Scene {

    private tilePattern!: Phaser.GameObjects.Image;

    constructor() {
        super({
            key: "background"
        });
    }

    create() {
        this.tilePattern = this.add.image(0, 0, "background");
        this.tilePattern.setOrigin(0, 0);
        this.tilePattern.setScale(this.cameras.main.width / this.tilePattern.width);

        //Apply default color
        this.setColor();

        //Notify that game has booted
        this.game.events.emit("booted");
    }

    setColor(color?: number) {
        this.cameras.main.setBackgroundColor(color ? color : 0x000000);
    }
}