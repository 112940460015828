import Phaser from "phaser";
import GameConstants, {EnemyType} from "@/game/pacman/utils/GameConstants";
import {Direction} from "@/game/shared/components/Joystick";
import ChaseAI from "../ai/ChaseAI";
import MovingSprite from "../entities/MovingSprite";
import PlayfulAI from "../ai/PlayfulAI";
import InterceptAI from "../ai/InterceptAI";
import FlankAI from "../ai/FlankAI";
import Player from "../entities/Player";
import BaseEnemyAI from "../ai/BaseEnemyAI";

export default class Enemy extends MovingSprite {

    //Readonly
    private readonly defaultSpeedMultiplier: number = 1;
    private readonly scatterSpeedMultiplier: number = 0.6;
    private readonly damagedSpeedMultiplier: number = 2;

    //Main
    private readonly ai: BaseEnemyAI;
    public readonly type: EnemyType;
    public readonly playerRef: Player;

    //Movement
    private readonly originalX: number;
    private readonly originalY: number;
    private lastDirectionTime = 0;

    //Misc
    private damageTween?: Phaser.Tweens.Tween;
    private invulnerable = false;

    constructor(scene: Phaser.Scene, x: number, y: number, name: string, baseLayer: Phaser.Tilemaps.TilemapLayer, player: Player, round: number) {
        super(scene, x + GameConstants.MAP_HALF_TILE_SIZE, y + GameConstants.MAP_HALF_TILE_SIZE, name, baseLayer, round);
        this.playerRef = player;

        //Add to scene & enable physics
        this.scene.add.existing(this);
        this.scene.physics.world.enable(this);

        //Store values
        this.originalX = x;
        this.originalY = y;
        this.type = name as EnemyType;
        this.ai = this.getAIByType();

        //Trigger animation
        this.anims.play(`animate-${this.type}`);
    }

    hit() {
        if(this.invulnerable) {
            return false;
        }
        this.invulnerable = true;

        //Play hit sound
        this.scene.sound.play("damage");

        //Set speed boost
        this.setSpeedMultiplier(this.damagedSpeedMultiplier);

        //Damage effect
        this.damageTween = this.scene.tweens.add({
            targets: this,
            alpha: 0.2,
            yoyo: true,
            repeat: 10,
            duration: 100,
            onComplete: () => {
                this.disableDamageTween();
                this.setSpeedMultiplier(this.scatterSpeedMultiplier);
                this.invulnerable = false;
            }
        });

        //Actual hit
        return true;
    }

    setScatterMode(enabled: boolean) {
        if(this.anims){
            this.ai.setScatterMode(enabled);

            //Get current animation frame & stop
            const currentFrame = this.anims.currentFrame.index - 1;
            this.anims.stop();

            //Stop tween & make sure state is set to vulnerable
            this.disableDamageTween();
            this.invulnerable = false;

            //Update texture based on mode
            if(enabled) {
                this.setTexture("enemy-hunted", currentFrame);
                this.anims.play("animate-enemy-hunted");
                this.setSpeedMultiplier(this.scatterSpeedMultiplier);
            }
            else {
                this.setTexture(this.type, currentFrame);
                this.anims.play(`animate-${this.type}`);
                this.setSpeedMultiplier(this.defaultSpeedMultiplier); //Make sure to reset this when disabling scatter
            }
        }
    }

    update(time: number, delta: number, baseX = 0, baseY = 0) {
        super.update(time, delta, baseX, baseY);

        //If we have no AI or are inactive, stop
        if(!this.ai || !this.active) {
            return;
        }

        //Get ideal direction
        const newDirection = this.ai.getOptimalDirection(baseX, baseY);
        if(newDirection !== Direction.NONE)
        {
            //Prevent moving in already current direction, and apply delay to prevent enemy from turning too fast
            if(this.direction === newDirection || (time - this.lastDirectionTime) < GameConstants.GAME_TURN_DELAY) {
                return;
            }
            this.lastDirectionTime = time;

            //Check if the determined direction is possible
            this.checkDirection(newDirection);
        }
        else {
            this.direction = Direction.NONE; //Must be set, otherwise enemies will get stuck in one-way only paths
        }
    }

    getOriginalPosition() {
        return new Phaser.Math.Vector2(this.originalX, this.originalY);
    }

    destroy(fromScene?: boolean) {
        this.disableDamageTween();
        super.destroy(fromScene);
    }

    private disableDamageTween() {
        this.damageTween?.remove();
        this.damageTween = undefined;
        this.setAlpha(1); //Make sure to set this
    }

    private getAIByType() {
        switch(this.type) {
            default:
            case EnemyType.CHASE:
                return new ChaseAI(this);
            case EnemyType.FLANK:
                return new FlankAI(this);
            case EnemyType.INTERCEPT:
                return new InterceptAI(this);
            case EnemyType.PLAYFUL:
                return new PlayfulAI(this);
        }
    }
}
