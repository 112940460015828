import Scaling from "@/game/shared/utils/Scaling";
import Constants from "@/game/shared/utils/Constants";
import GameConstants from "@/game/pacman/utils/GameConstants";
import {parseHexColor} from "@/game/shared/utils/Helpers";
import Setup from "@/helpers/Setup";

export default class PopupDialog extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene, x: number, y: number, popupText: string, renderOverlay = false, fadeIn = false) {
        super(scene, x, y);

        //Text
        const textColorPrimary = parseHexColor(Setup.getValue("settings.game.colors.primarytextcolor"));
        const text = new Phaser.GameObjects.Text(this.scene, 0, 0, popupText, {
            color: textColorPrimary.colorString,
            fontFamily: Constants.FONT_BOLD,
            fontSize: `${28 * Scaling.DPR}px`
        });

        //Background
        const secondaryColor = parseHexColor(Setup.getValue("settings.game.colors.secondarycolor"));
        const background = new Phaser.GameObjects.Graphics(this.scene);
        const boxWidth = text.width + (2 * GameConstants.UI_DEFAULT_PADDING);
        const boxHeight = text.height + (2 * GameConstants.UI_DEFAULT_PADDING);

        let backgroundWidth;
        let backgroundHeight;

        if(renderOverlay) {
            backgroundWidth = this.scene.sys.cameras.main.width;
            backgroundHeight = this.scene.sys.cameras.main.height;

            //Draw overlay
            background.fillStyle(0x000000, 0.7);
            background.fillRect(0, 0, backgroundWidth, backgroundHeight);

            //Draw box
            const boxX = (backgroundWidth - boxWidth) / 2;
            const boxY = (backgroundHeight - boxHeight) / 2;
            background.fillStyle(secondaryColor.color, secondaryColor.alpha);
            background.fillRoundedRect(boxX, boxY, boxWidth, boxHeight, GameConstants.UI_BOX_RADIUS);

            //Position text properly
            text.setPosition((boxWidth - text.width) / 2 + boxX, (boxHeight - text.height) / 2 + boxY);
        }
        else {
            backgroundWidth = boxWidth;
            backgroundHeight = boxHeight;

            //Draw box
            background.fillStyle(secondaryColor.color, secondaryColor.alpha);
            background.fillRoundedRect(0, 0, boxWidth, boxHeight, GameConstants.UI_BOX_RADIUS);

            //Position text properly
            text.setPosition((boxWidth - text.width) / 2, (boxHeight - text.height) / 2);
        }

        //Set dimensions
        this.setSize(backgroundWidth, backgroundHeight);

        //Add fields
        this.add([background, text]);
        this.scene.add.existing(this);

        //Perform animation, if set
        if(fadeIn) {
            this.fadeIn();
        }
    }

    fadeIn() {
        this.setAlpha(0);

        //Set tween
        this.scene.tweens.add({
            targets: this,
            duration: 400,
            alpha: 1,
            onComplete: () => {
                this.emit("visible");
            }
        })
    }
}