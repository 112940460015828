import Phaser from "phaser";
import Scaling from "@/game/shared/utils/Scaling";

export enum Direction {
    NONE,
    LEFT,
    RIGHT,
    UP,
    DOWN,
    LEFT_UP,
    LEFT_DOWN,
    RIGHT_UP,
    RIGHT_DOWN,
}

export enum JoystickMode {
    FOUR,
    EIGHT,
    ANALOG
}

interface IJoystickConfig {
    baseKey?: string,
    knobKey?: string,
    isFixed?: boolean,
    mode?: JoystickMode
}

export default class Joystick extends Phaser.GameObjects.Container {

    private readonly knobOffset: number = Scaling.getPixelbyDPR(18);

    //Visuals
    private readonly base: Phaser.GameObjects.Image;
    private readonly knobImage: Phaser.GameObjects.Image;
    private readonly isFixed: boolean;
    private readonly joystickMode: JoystickMode;

    //Keyboard (for fallback)
    private keyLeft: Phaser.Input.Keyboard.Key;
    private keyRight: Phaser.Input.Keyboard.Key;
    private keyUp: Phaser.Input.Keyboard.Key;
    private keyDown: Phaser.Input.Keyboard.Key;

    //State
    private canSetPosition: boolean = true;
    private pointerRef?: Phaser.Input.Pointer;
    private rawDirection: Phaser.Math.Vector2;
    private lastDirection: Direction;

    /**
     * @param scene current scene
     * @param x x position
     * @param y y position
     * @param config override default settings, see interface for specific fields
     */
    constructor(scene: Phaser.Scene, x: number, y: number, config: IJoystickConfig = {}) {
        super(scene, x, y);
        this.scene.add.existing(this);

        // Apply config
        this.isFixed = config.isFixed || true;
        this.joystickMode = config.mode || JoystickMode.FOUR;
        this.rawDirection = new Phaser.Math.Vector2(0, 0);
        this.lastDirection = Direction.NONE;

        // Create images
        this.base = scene.add.image(0, 0, config.baseKey || "joystick");
        this.knobImage = scene.add.image(0, 0, config.knobKey || "joystick-knob");
        this.add(this.base);
        this.add(this.knobImage);

        //Set keys
        this.keyLeft = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
        this.keyRight = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);
        this.keyUp = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W);
        this.keyDown = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S);

        //Set touch events event
        this.base.setInteractive();
        this.base.on("pointerdown", (pointer: Phaser.Input.Pointer) => this.pointerRef = pointer);
        this.scene.input.on("pointerup", (pointer: Phaser.Input.Pointer) => this.handleRelease(pointer));
        this.scene.input.on("gameout", (pointer: Phaser.Input.Pointer) => this.handleRelease(pointer));
    }

    /**
     * Update function, called from main loop
     * Checks inputs and positions knob according to it
     */
    update() {
        let x = 0;
        let y = 0;

        //Handle any input
        if (this.isAnyKeyDown())
        {
            //Map key input to XY
            if(this.keyLeft.isDown) {
                x = -1;
            }  else if(this.keyRight.isDown) {
                x = 1;
            }
            if(this.keyUp.isDown) {
                y = -1;
            } else if(this.keyDown.isDown) {
                y = 1;
            }
        }
        else if (this.pointerRef?.isDown)
        {
            // Set touch position, if enabled
            if (this.canSetPosition && !this.isFixed) {
                this.setPosition(this.pointerRef.x, this.pointerRef.y);
                this.canSetPosition = false;
            }

            // Calc difference in initial value and current value
            x = this.pointerRef.x - this.x;
            y = this.pointerRef.y - this.y;
        }

        //Calculate position based on input
        if(x || y) {
            this.rawDirection = this.calculateDirection(x, y);
        }
        else {
            this.canSetPosition = true;
            this.rawDirection = Phaser.Math.Vector2.ZERO;
        }

        //Set knob image position
        this.knobImage.setPosition(this.rawDirection.x * this.knobOffset, this.rawDirection.y * this.knobOffset);
    }


    /**
     * Overridden destroy, removes scene events set from here
     */
    destroy(fromScene?: boolean) {
        this.keyLeft.destroy();
        this.keyRight.destroy();
        this.keyUp.destroy();
        this.keyDown.destroy();
        this.scene.input.off("pointerup");
        this.scene.input.off("gameout");
        super.destroy(fromScene);
    }

    /**
     * Returns enum value of Direction based on current input
     * Works for four and eight direction modes
     * @return Direction enum of current direction
     */
    getDirection(): Direction
    {
        //In analog mode, a specific direction is calculated - raw output must be used
        if(this.joystickMode === JoystickMode.ANALOG) {
            return Direction.NONE;
        }

        //Map raw directions to actual directions
        if(this.rawDirection.x === 1) {
            if(this.rawDirection.y === 1) {
                return Direction.RIGHT_DOWN;
            } else if(this.rawDirection.y === -1) {
                return Direction.RIGHT_UP;
            } else {
                return Direction.RIGHT;
            }
        }
        else if(this.rawDirection.x === -1) {
            if(this.rawDirection.y === 1) {
                return Direction.LEFT_DOWN;
            }
            else if(this.rawDirection.y === -1) {
                return Direction.LEFT_UP;
            } else {
                return Direction.LEFT;
            }
        }
        else if(this.rawDirection.y === 1) {
            return Direction.DOWN;
        }
        else if(this.rawDirection.y === -1) {
            return Direction.UP;
        }

        //Fallback to none, if no valid direction is detected
        return Direction.NONE;
    }

    /**
     * Returns raw version of current input, mainly useful for games analog types of input
     * @return Vector2 last value of normalized vector of input (used in game logic)
     */
    getRawDirection(): Phaser.Math.Vector2 {
        return this.rawDirection;
    }

    /**
     * Disables touch input
     */
    private handleRelease(pointer: Phaser.Input.Pointer): void {
        if(this.pointerRef?.id !== pointer.id) {
            return;
        }
        this.pointerRef = undefined;
    }


    /**
     * Calculates normalized direction vector based on provided XY coordinates
     * @param x input x position
     * @param y input y position
     * @return Vector2 normalized vector of input
     */
    private calculateDirection(x: number, y: number): Phaser.Math.Vector2 {
        const diffNormalized = new Phaser.Math.Vector2(x, y).normalize();

        // only normalize one axis
        // pick the biggest value
        if (this.joystickMode === JoystickMode.FOUR) {
            if (Math.abs(diffNormalized.x) > Math.abs(diffNormalized.y)) {
                diffNormalized.x = Math.round(diffNormalized.x);
                diffNormalized.y = 0;
            } else {
                diffNormalized.x = 0;
                diffNormalized.y = Math.round(diffNormalized.y);
            }
        } else if (this.joystickMode === JoystickMode.EIGHT) {
            diffNormalized.x = Math.round(diffNormalized.x);
            diffNormalized.y = Math.round(diffNormalized.y);
        }

        //Return output
        return diffNormalized;
    }

    /**
     * Checks if any keyboard key is pressed
     * @return boolean value is true when keys are pressed
     */
    private isAnyKeyDown(): boolean {
        return this.keyDown.isDown || this.keyUp.isDown || this.keyLeft.isDown || this.keyRight.isDown;
    }
}