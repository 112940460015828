import Phaser from "phaser";
import GameConstants from "@/game/pacman/utils/GameConstants";
import {getGameSpeed, isValidTile} from "@/game/pacman/utils/Helpers";
import {Direction} from "@/game/shared/components/Joystick";

export default class MovingSprite extends Phaser.GameObjects.Sprite {

    //Readonly
    private readonly oppositeDirection: Direction[] = [Direction.NONE, Direction.RIGHT, Direction.LEFT, Direction.DOWN, Direction.UP];
    private readonly baseSpeed: number;
    public readonly baseLayer: Phaser.Tilemaps.TilemapLayer;

    //Tracking
    public currentTile!: Phaser.Tilemaps.Tile;
    public surroundingTiles: (Phaser.Tilemaps.Tile | null)[] = [null];

    //Movement
    private speedMultiplier = 1;
    private turnPoint: Phaser.Math.Vector2 = new Phaser.Math.Vector2();
    protected turnDirection: Direction = Direction.NONE;
    public direction: Direction = Direction.NONE;
    private lastDirection: Direction = Direction.NONE;

    //Misc.
    private turnRotation = false;

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string, baseLayer: Phaser.Tilemaps.TilemapLayer, round: number) {
        super(scene, x, y, texture);
        this.baseLayer = baseLayer;

        //Set speed
        this.baseSpeed = getGameSpeed(round);
    }

    protected checkDirection(direction: Direction) {
        const targetTile = this.surroundingTiles[direction];

        //Check if next tile is valid
        if(!isValidTile(targetTile)) {
            return;
        }

        //Allow direct movement, if in opposite direction
        if(this.direction === this.oppositeDirection[direction]) {
            this.move(direction);
            return;
        }

        //If new direction, set optimal turning point
        const currentTilePosition = this.baseLayer.tileToWorldXY(this.currentTile.x, this.currentTile.y);
        this.turnPoint.set(currentTilePosition.x + GameConstants.MAP_HALF_TILE_SIZE, currentTilePosition.y + GameConstants.MAP_HALF_TILE_SIZE);
        this.turnDirection = direction;
    }

    private track() {
        this.currentTile = this.baseLayer.getTileAtWorldXY(this.x, this.y);

        //If we can't determine our current tile, stop
        if(!this.currentTile) {
            return;
        }

        //Keep track of surrounding tiles
        this.surroundingTiles[Direction.LEFT] = this.baseLayer.getTileAt(this.currentTile.x - 1, this.currentTile.y);
        this.surroundingTiles[Direction.RIGHT] = this.baseLayer.getTileAt(this.currentTile.x + 1, this.currentTile.y);
        this.surroundingTiles[Direction.UP] = this.baseLayer.getTileAt(this.currentTile.x, this.currentTile.y - 1);
        this.surroundingTiles[Direction.DOWN] = this.baseLayer.getTileAt(this.currentTile.x, this.currentTile.y + 1);
    }

    private turn() {
        const floorX = Math.floor(this.x);
        const floorY = Math.floor(this.y);

        //Check if we are close enough to turning point
        if(!Phaser.Math.Fuzzy.Equal(floorX, this.turnPoint.x, GameConstants.GAME_TURN_THRESHOLD) || !Phaser.Math.Fuzzy.Equal(floorY, this.turnPoint.y, GameConstants.GAME_TURN_THRESHOLD)) {
            return;
        }

        //Align player with turn point
        this.setPosition(this.turnPoint.x, this.turnPoint.y);
        (this.body as Phaser.Physics.Arcade.Body).reset(this.turnPoint.x, this.turnPoint.y);

        //Move
        this.move(this.turnDirection);
    }

    private move(direction: Direction) {
        let speed = this.baseSpeed * this.speedMultiplier;

        //Check multiplier limit
        if(this.speedMultiplier > 1 && speed > GameConstants.GAME_SPEED_MULTIPLIER_LIMIT) {
            speed = GameConstants.GAME_SPEED_MULTIPLIER_LIMIT;
        }

        //Apply
        switch(direction) {
            case Direction.LEFT:
                this.body.velocity.x = -speed;
                if(this.turnRotation) {
                    this.setAngle(180);
                }
                break;
            case Direction.RIGHT:
                this.body.velocity.x = speed;
                if(this.turnRotation) {
                    this.setAngle(0);
                }
                break;
            case Direction.UP:
                this.body.velocity.y = -speed;
                if(this.turnRotation) {
                    this.setAngle(-90);
                }
                break;
            case Direction.DOWN:
                this.body.velocity.y = speed;
                if(this.turnRotation) {
                    this.setAngle(90);
                }
                break;
        }

        //Sync direction
        this.direction = direction;
        this.turnDirection = Direction.NONE;
    }

    halt() {
        this.body.velocity.x = 0;
        this.body.velocity.y = 0;
    }

    protected toggleTurnRotation(enable: boolean) {
        this.turnRotation = enable;

        //Reset rotation, if disabled
        if(!enable) {
            this.setAngle(0);
        }
    }

    protected setSpeedMultiplier(multiplier: number) {
        this.speedMultiplier = multiplier;

        //Apply to current speed
        this.move(this.direction);
    }

    update(time: number, delta: number, baseX = 0, baseY = 0) {
        this.track();

        //Attempt turning
        if(this.turnDirection !== Direction.NONE) {
            this.turn();
        }
    }
}
