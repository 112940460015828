import Phaser from "phaser";
import Scaling from "@/game/shared/utils/Scaling";
import Constants from "@/game/shared/utils/Constants";
import {parseHexColor} from "@/game/shared/utils/Helpers";
import Setup from "@/helpers/Setup";

export default class ScoreNotifier extends Phaser.GameObjects.Container {

    private readonly visibilityDuration: number = 600;

    constructor(scene: Phaser.Scene, x: number, y: number, score: number) {
        super(scene, x, y);

        //Draw text box
        const textColorSecondary = parseHexColor(Setup.getValue("settings.game.colors.secondarytextcolor"));
        const strokeColor = parseHexColor(Setup.getValue("settings.game.colors.scorenotifierstrokecolor"));
        const text = new Phaser.GameObjects.Text(this.scene, 0, 0, score.toString(), {
            fontFamily: Constants.FONT_BOLD,
            fontSize: `${16 * Scaling.DPR}px`,
            color: textColorSecondary.colorString,
            stroke: strokeColor.colorString,
            strokeThickness: Scaling.getPixelbyDPR(2)
        });
        text.setPosition(-(text.width / 2), -(text.height / 2));

        //Add to scene
        this.add(text);
        this.scene.add.existing(this);

        //Tween out
        this.scene.tweens.add({
            targets: this,
            y: this.y - 25 * Scaling.DPR,
            duration: this.visibilityDuration,
            onComplete: () => {
                this.destroy(true);
            }
        });

        //Tween fade
        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            delay: this.visibilityDuration / 2,
            duration: this.visibilityDuration,
            onComplete: () => {
                this.destroy(true);
            }
        });
    }
}