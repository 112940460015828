import Phaser from "phaser";
import GameConstants from "@/game/pacman/utils/GameConstants";
import MovingSprite from "./MovingSprite";
import {Direction} from "@/game/shared/components/Joystick";

export default class Player extends MovingSprite {

    //Readonly
    private readonly defaultSpeedMultiplier: number = 1;
    private readonly damagedSpeedMultiplier: number = 1.5;

    //Misc.
    private damageTween?: Phaser.Tweens.Tween;
    private lives: number;
    private invulnerable = false;
    private poweredUp = false;

    constructor(scene: Phaser.Scene, x: number, y: number, baseLayer: Phaser.Tilemaps.TilemapLayer, round: number) {
        super(scene, x + GameConstants.MAP_HALF_TILE_SIZE, y + GameConstants.MAP_HALF_TILE_SIZE, "player", baseLayer, round);

        //Add to scene & enable physics
        this.scene.add.existing(this);
        this.scene.physics.world.enable(this);

        //Start animation
        this.scene.anims.play("animate-player", this);

        //Decrease body size slightly, otherwise you get stuck sometimes
        const body = this.body as Phaser.Physics.Arcade.Body;
        body.setCircle(GameConstants.MAP_HALF_TILE_SIZE);
        body.setFriction(0, 0);

        //Get lives from registry
        this.lives = this.scene.registry.get("lives");

        //Set rotation mode
        this.toggleTurnRotation(true);
    }

    getNextPointInDirection(tiles = 2) {
        const vector = new Phaser.Math.Vector2();
        vector.setToPolar(this.rotation).normalize();

        //Get XY for position in front of player
        const nextX = this.x + (vector.x * (tiles * GameConstants.MAP_TILE_SIZE));
        const nextY = this.y + (vector.y * (tiles * GameConstants.MAP_TILE_SIZE));

        //Return point
        return new Phaser.Math.Vector2(nextX, nextY);
    }

    hit() {
        if(this.invulnerable || !this.active) {
            return;
        }

        //Play hit sound
        this.scene.sound.play("damage");

        //Subtract lives
        this.lives--;
        this.scene.registry.set("lives", this.lives);

        //Trigger gameover if no lives are left
        if(this.lives <= 0) {
            this.scene.events.emit("precleanup");
            this.setAngle(0);

            //Play death animation & sound
            this.scene.sound.play("death");
            this.anims.stop();
            this.anims.play("animate-player-death");

            //Wait for completion & hide
            this.on("animationcomplete", () => {
                this.setVisible(false);

                //Small delay, then go to gameover state
                this.scene.time.addEvent({
                    delay: 250,
                    callback: () => this.scene.events.emit("gameover")
                });
            });
            return;
        }

        //Set speed boost
        this.setSpeedMultiplier(this.damagedSpeedMultiplier);

        //Begin animation
        this.invulnerable = true;
        this.damageTween = this.scene.tweens.add({
            targets: this,
            alpha: 0.2,
            yoyo: true,
            repeat: 20,
            duration: 50,
            onComplete: () => {
                this.disableDamageTween();
                this.setSpeedMultiplier(this.defaultSpeedMultiplier);
                this.invulnerable = false;
            }
        });
    }

    setPoweredUp(enabled: boolean) {
        this.poweredUp = enabled;
    }

    isPoweredUp() {
        return this.poweredUp;
    }

    private disableDamageTween() {
        this.damageTween?.remove();
        this.damageTween = undefined;
        this.setAlpha(1); //Make sure to set this
    }
        
    update(time: number, delta: number, direction: Direction) {
        super.update(time, delta);

        //Check direction
        if (direction === Direction.LEFT && this.direction !== Direction.LEFT) {
            this.checkDirection(Direction.LEFT);
        } else if (direction === Direction.RIGHT && this.direction !== Direction.RIGHT) {
            this.checkDirection(Direction.RIGHT);
        } else if (direction === Direction.UP && this.direction !== Direction.UP) {
            this.checkDirection(Direction.UP);
        } else if (direction === Direction.DOWN && this.direction !== Direction.DOWN) {
            this.checkDirection(Direction.DOWN)
        } else {
            this.turnDirection = Direction.NONE
        }
    }
}
