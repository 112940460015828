import Phaser from "phaser";
import GameConstants from "@/game/pacman/utils/GameConstants";
import Scaling from "@/game/shared/utils/Scaling";

export function getGameSpeed(round: number): number {
    const baseSpeed = GameConstants.GAME_BASE_SPEED + (GameConstants.GAME_SPEED_INCREMENT * round)
    const maxSpeed = GameConstants.GAME_SPEED_LIMIT * Scaling.DPR;
    return baseSpeed < maxSpeed ? baseSpeed : maxSpeed;
}

export function isValidTile(tile: Phaser.Tilemaps.Tile | null): boolean {
    if(tile) {
        const collides = tile.properties["collides"] || false;
        return !collides;
    }
    return false;
}