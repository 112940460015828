import Enemy from "../entities/Enemy";
import GameConstants from "@/game/pacman/utils/GameConstants";
import {Direction} from "@/game/shared/components/Joystick";
import Phaser from "phaser";
import {isValidTile} from "@/game/pacman/utils/Helpers";
import {IEnemyAI} from "@/game/pacman/interfaces/IEnemyAI";

//INFO: Description of enemy AI's
//https://gameinternals.com/understanding-pac-man-ghost-behavior

export default class BaseEnemyAI implements IEnemyAI {

    //Enemy
    protected enemyInstance: Enemy;

    //State
    protected scatterMode = false;

    constructor(enemy: Enemy) {
        this.enemyInstance = enemy;
    }

    //Calculates optimal direction based on target
    protected calculateOptimalDirection(targetPosition: Phaser.Math.Vector2): Direction {
        const oppositeDirection = this.getOppositeDirection(this.enemyInstance.direction);
        const validDirections = this.getValidDirections(oppositeDirection);

        //Go through direction options
        let newDirectionDistance = 0;
        let newDirection = Direction.NONE;

        for(const direction of validDirections) {
            const tile = this.enemyInstance.surroundingTiles[direction];

            //Check if tile is a valid direction
            if(!tile || !isValidTile(tile)) {
                continue;
            }

            //Calculate distance between target and new position
            const worldPosition = this.enemyInstance.baseLayer.tileToWorldXY(tile.x, tile.y);
            const centerPosition = new Phaser.Math.Vector2(worldPosition.x + GameConstants.MAP_HALF_TILE_SIZE, worldPosition.y + GameConstants.MAP_HALF_TILE_SIZE);
            const distance = Phaser.Math.Distance.BetweenPoints(targetPosition, centerPosition);

            //Keep track of optimal position
            if(newDirection === Direction.NONE) {
                newDirection = direction;
                newDirectionDistance = distance;
            }
            if(distance < newDirectionDistance) {
                newDirection = direction;
                newDirectionDistance = distance;
            }
        }

        //Return result
        return newDirection;
    }


    //Setter for scatter mode
    public setScatterMode(enabled: boolean) {
        this.scatterMode = enabled;
    }


    //Direction helpers
    private getOppositeDirection(currentDirection: Direction): Direction {
        switch (currentDirection) {
            case Direction.LEFT:
                return Direction.RIGHT;
            case Direction.RIGHT:
                return Direction.LEFT;
            case Direction.UP:
                return Direction.DOWN;
            case Direction.DOWN:
                return Direction.UP;
        }
        return Direction.NONE;
    }

    private getValidDirections(oppositeDirection: Direction): Direction[] {
        const validDirectionList = [Direction.UP, Direction.LEFT, Direction.DOWN, Direction.RIGHT];

        //Allow all directions, if currently not moving
        if(oppositeDirection === Direction.NONE) {
            return validDirectionList;
        }

        //Return directions except the opposite one
        return validDirectionList.filter(direction => direction !== oppositeDirection);
    }


    //Main function which returns best direction
    public getOptimalDirection(x: number, y: number): Direction {
        let targetPosition;

        //Calculate position normally, unless in scatter mode where the original position is enforced
        if(!this.scatterMode) {
            targetPosition = this.getTargetPosition(x, y);
        }
        else {
            targetPosition = this.enemyInstance.getOriginalPosition();
        }

        //Return direction
        return this.calculateOptimalDirection(targetPosition);
    }


    //Base function to override
    public getTargetPosition(x: number, y: number): Phaser.Math.Vector2 {
        return new Phaser.Math.Vector2();
    }
}
