import Phaser from "phaser";
import GameConstants, {EnemyType} from "../utils/GameConstants";
import level1 from "../data/map1.json";
import level2 from "../data/map2.json";
import level3 from "../data/map3.json";
import level4 from "../data/map4.json";
import level5 from "../data/map5.json";
import Setup from "@/helpers/Setup";
import Scaling from "@/game/shared/utils/Scaling";
import FlowHelper from "@/helpers/Flow";
import {parseHexColor} from "@/game/shared/utils/Helpers";

export default class Load extends Phaser.Scene {

    private logo!: Phaser.GameObjects.Image;
    private loaderBase!: Phaser.GameObjects.Graphics;
    private loaderLoaded!: Phaser.GameObjects.Graphics;

    constructor() {
        super({
            key: "load"
        });
    }

    preload() {
        this.logo = this.add.image(this.cameras.main.width / 2, this.cameras.main.height / 2 - Scaling.getPixelbyDPR(60), "logo");

        //Setup loader
        const loaderWidth = this.cameras.main.width * 0.6;
        const loaderHeight = 16 * Scaling.DPR;
        const loaderOffset = 3 * Scaling.DPR;
        const loaderOffsetDouble = loaderOffset * 2;

        this.loaderBase = this.add.graphics({
            x: this.logo.getBounds().centerX - loaderOffset,
            y: this.logo.getBounds().bottom - loaderOffset + (100 * Scaling.DPR)
        });

        const primaryColor = parseHexColor(Setup.getValue('settings.game.colors.primarycolor'));
        const secondaryColor = parseHexColor(Setup.getValue('settings.game.colors.secondarycolor'));
        this.loaderBase.fillStyle(primaryColor.color, primaryColor.alpha);
        this.loaderBase.fillRect(-(loaderWidth) / 2, 0, loaderWidth + loaderOffsetDouble, loaderHeight + loaderOffsetDouble);

        this.loaderLoaded = this.add.graphics({
            x: this.logo.getBounds().centerX - (loaderWidth) / 2,
            y: this.logo.getBounds().bottom + (100 * Scaling.DPR)
        });

        //Loading event
        this.load.on("progress", (value: number) => {
            this.loaderLoaded.clear();
            this.loaderLoaded.fillStyle(secondaryColor.color, secondaryColor.alpha);
            this.loaderLoaded.fillRect(0, 0, (loaderWidth * value), loaderHeight);
        });

        //Map
        this.load.tilemapTiledJSON("level1-map", level1);
        this.load.tilemapTiledJSON("level2-map", level2);
        this.load.tilemapTiledJSON("level3-map", level3);
        this.load.tilemapTiledJSON("level4-map", level4);
        this.load.tilemapTiledJSON("level5-map", level5);
        this.load.image("tilemap-base", Scaling.ImagePath("tilemap-base", "png", 3));
        this.load.image("tilemap-pickup", Scaling.ImagePath("tilemap-pickups", "png", 3));

        //Spritesheets
        this.load.spritesheet(EnemyType.CHASE, Scaling.ImagePath("enemy-chase", "png"), {
            frameWidth: GameConstants.MAP_TILE_SIZE,
            frameHeight: GameConstants.MAP_TILE_SIZE
        });
        this.load.spritesheet(EnemyType.FLANK, Scaling.ImagePath("enemy-flank", "png"), {
            frameWidth: GameConstants.MAP_TILE_SIZE,
            frameHeight: GameConstants.MAP_TILE_SIZE
        });
        this.load.spritesheet(EnemyType.INTERCEPT, Scaling.ImagePath("enemy-intercept", "png"), {
            frameWidth: GameConstants.MAP_TILE_SIZE,
            frameHeight: GameConstants.MAP_TILE_SIZE
        });
        this.load.spritesheet(EnemyType.PLAYFUL, Scaling.ImagePath("enemy-playful", "png"), {
            frameWidth: GameConstants.MAP_TILE_SIZE,
            frameHeight: GameConstants.MAP_TILE_SIZE
        });
        this.load.spritesheet("enemy-hunted", Scaling.ImagePath("enemy-hunted", "png"), {
            frameWidth: GameConstants.MAP_TILE_SIZE,
            frameHeight: GameConstants.MAP_TILE_SIZE
        });

        //Player spritesheet
        const characterSelectionState = FlowHelper.getState("characterSelection");
        const characterKey = (characterSelectionState?.title as string || "").replaceAll(" ", "_").toLowerCase();

        this.load.spritesheet("player", Scaling.ImagePath(characterKey.length ? `player_${characterKey}` : "player", "png"), {
            frameWidth: GameConstants.MAP_TILE_SIZE,
            frameHeight: GameConstants.MAP_TILE_SIZE,
        });

        //Images
        this.load.image("ui-coins", Scaling.ImagePath("icon-points", "png"));
        this.load.image("ui-lives", Scaling.ImagePath("icon-lifes", "png"));
        this.load.image("joystick", Scaling.ImagePath("joystick", "png"));
        this.load.image("joystick-knob", Scaling.ImagePath("joystick-knob", "png"));

        //Sounds - locked
        this.load.audio("damage", "sounds/games/pacman/damage.mp3");
        this.load.audio("death", "sounds/games/pacman/death.mp3");
        this.load.audio("victory", "sounds/games/pacman/victory.mp3");
        this.load.audio("collect-coin", "sounds/games/pacman/coin.mp3");
        this.load.audio("power-up", "sounds/games/pacman/power_up.mp3");
        this.load.audio("power-down", "sounds/games/pacman/power_down.mp3");

        //Sounds - customizable
        this.load.audio("music-loop1", Setup.getSound("music_loop1.mp3"));
        this.load.audio("music-loop2", Setup.getSound("music_loop2.mp3"));
        this.load.audio("music-loop3", Setup.getSound("music_loop3.mp3"));
        this.load.audio("music-loop4", Setup.getSound("music_loop4.mp3"));

        //Completion event
        this.load.on("complete", async () => {
            this.setupAnimations();
            await this.exitAnimation();
            this.scene.stop()
            this.game.events.emit("loaded");
        });
    }

    setupAnimations() {
        const baseSettings: any = {
            frameRate: 15,
            repeat: -1,
            yoyo: true
        };

        //Create animations using base settings
        this.anims.create({
            ...baseSettings,
            key: `animate-${EnemyType.CHASE}`,
            frames: this.anims.generateFrameNumbers(EnemyType.CHASE, { start: 0, end: 1 })
        });
        this.anims.create({
            ...baseSettings,
            key: `animate-${EnemyType.FLANK}`,
            frames: this.anims.generateFrameNumbers(EnemyType.FLANK, { start: 0, end: 1 })
        });
        this.anims.create({
            ...baseSettings,
            key: `animate-${EnemyType.INTERCEPT}`,
            frames: this.anims.generateFrameNumbers(EnemyType.INTERCEPT, { start: 0, end: 1 })
        });
        this.anims.create({
            ...baseSettings,
            key: `animate-${EnemyType.PLAYFUL}`,
            frames: this.anims.generateFrameNumbers(EnemyType.PLAYFUL, { start: 0, end: 1 }),
        });
        this.anims.create({
            ...baseSettings,
            key: "animate-enemy-hunted",
            frames: this.anims.generateFrameNumbers("enemy-hunted", { start: 0, end: 1 }),
        });
        this.anims.create({
            ...baseSettings,
            key: 'animate-player',
            frames: this.anims.generateFrameNumbers('player', { start: 0, end: 2 }),
            frameRate: 10,
        })
        this.anims.create({
            key: "animate-player-death",
            frames: this.anims.generateFrameNumbers("player", { start: 2, end: 9 }),
            frameRate: 9
        });
    }

    exitAnimation(): Promise<void> {
        return new Promise(resolve => {
            this.tweens.add({
                targets: [this.logo, this.loaderLoaded, this.loaderBase],
                alpha: 0,
                duration: 400,
                ease: "ease",
                onComplete: () => resolve()
            })
        });
    }
}