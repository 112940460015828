import Phaser from "phaser";
import WebFont from "webfontloader";
import Scaling from "@/game/shared/utils/Scaling";
import Constants from "@/game/shared/utils/Constants";
import GameConstants from "@/game/pacman/utils/GameConstants";

export default class Boot extends Phaser.Scene {

    constructor() {
        super({
            key: "boot"
        });
    }

    preload() {
        GameConstants.updateWithScaling();

        //Load Fonts
        this.load.on("complete", () => {
            WebFont.load({
                custom: {
                    families: [Constants.FONT_BOLD, Constants.FONT_REGULAR]
                },
                active: () => this.booted(true),
                inactive: () => this.booted(false)
            });
        });

        //Load images
        this.load.image("logo", Scaling.ImagePath("logo", "png"));
        this.load.image("background", Scaling.ImagePath("bg-game", "png", 3));
    }

    booted(fontsLoaded: boolean) {
        // Fonts: Loaded check 
        if (!fontsLoaded) {
            return console.error("fonts failed to load...");
        }

        // Scene: launch parallel background scene
        //Notify that game has booted
        this.scene.launch("background");
        this.scene.stop();
    }
}
