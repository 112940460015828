import BaseEnemyAI from "../ai/BaseEnemyAI";
import {IEnemyAI} from "@/game/pacman/interfaces/IEnemyAI";
import GameConstants from "@/game/pacman/utils/GameConstants";
import Phaser from "phaser";
import Enemy from "../entities/Enemy";
import ChaseAI from "../ai/ChaseAI";

//Orange ghost
export default class PlayfulAI extends BaseEnemyAI implements IEnemyAI {

    private chaseAI;

    constructor(enemy: Enemy) {
        super(enemy);

        //Create chase AI instance
        this.chaseAI = new ChaseAI(enemy);
    }

    public getTargetPosition(x: number, y: number): Phaser.Math.Vector2 {
        const minDistance = 8 * GameConstants.MAP_TILE_SIZE;

        //Calculate distance between player & enemy
        const distance = Phaser.Math.Distance.Between(this.enemyInstance.playerRef.x, this.enemyInstance.playerRef.y, this.enemyInstance.x, this.enemyInstance.y);

        //Switch behaviour based on distance
        if(distance > minDistance) {
            return this.chaseAI.getTargetPosition(x, y);
        }
        else {
            return this.enemyInstance.getOriginalPosition();
        }
    }
}
