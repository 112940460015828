import {ITileAnimationData, ITileData} from "@/game/pacman/interfaces/ITileData";
import Phaser from "phaser";

export default class AnimatedTile {

    private tile: Phaser.Tilemaps.Tile;
    private tileAnimationData: ITileAnimationData[];
    private elapsedTime: number;

    private readonly firstGID: number;
    private readonly animationDuration: number;

    constructor(tile: Phaser.Tilemaps.Tile, tileAnimationData: ITileAnimationData[], firstGID: number) {
        this.tile = tile;
        this.tileAnimationData = tileAnimationData;
        this.firstGID = firstGID;
        this.elapsedTime = 0;
        this.animationDuration = tileAnimationData.length ? tileAnimationData[0].duration * tileAnimationData.length : 0;
    }

    update(delta: number) {
        this.elapsedTime += delta;
        this.elapsedTime %= this.animationDuration;

        //Get active index
        const frameIndex = Math.floor(this.elapsedTime / this.tileAnimationData[0].duration);

        //Set current tile
        this.tile.index = this.tileAnimationData[frameIndex].tileid + this.firstGID;
    }

    static getAnimationTiles(map: Phaser.Tilemaps.Tilemap, tileSet: Phaser.Tilemaps.Tileset | Phaser.Tilemaps.Tileset[]): AnimatedTile[] {
        const output: AnimatedTile[] = [];
        const tileSetList = Array.isArray(tileSet) ? tileSet : [tileSet];
        
        //Loop through tilesets
        for(const tileSet of tileSetList) {
            const tileData = tileSet.tileData as ITileData;

            //Loop through tile data
            for(const tileid in tileData) {
                const tileId = parseInt(tileid);

                //Go through map layers
                map.layers.forEach(layer =>
                {
                    //Skip static layers, because they're... static
                    if(layer.tilemapLayer && layer.tilemapLayer.type === "StaticTilemapLayer") {
                        return;
                    }

                    //Loop through tiles (first rows, then actual tiles)
                    layer.data.forEach(tileRow => {
                        tileRow.forEach(tile =>
                        {
                            //Select the correct initial animation frames from tile data
                            const animationData = tileData[tileId].animation;
                            if(tile.index - tileSet.firstgid === tileId && animationData) {
                                const animatedTile = new AnimatedTile(tile, animationData, tileSet.firstgid);
                                output.push(animatedTile);
                            }
                        });
                    });
                });
            }
        }

        //Return output
        return output;
    }
}